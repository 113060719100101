.accordion-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    background-color: #ffffff;
    color: black;
}

.accordion-title:hover {
    background-color: #ffffff;
}

.accordion-title,
.accordion-content {
    padding: .5rem;
}

.accordion-content {
    background-color: #ffffff;
}

.nominee-form {
    height: 65vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.nominee-form::-webkit-scrollbar {
    width: 2px;
}

.nominee-form::-webkit-scrollbar-thumb {
    background-color: black;
}